import React, { useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { useDisabled } from 'context'
import API from 'config/api'
import notification from 'antd/es/notification'
import { Container } from 'components'
import { useStateWithMerge } from 'hooks'
import Lines from '../index'
import LineButton from '../LineButton'
import Error from '../../Identification/IdBeforeLines/Schedules/Error'
import ListItem from './LineItem'

async function handleLineClick({
  patientRut,
  patientRutDv,
  ripleyIntegrationButton,
  unitId,
  setState,
  patientDocument,
}) {
  try {
    const scheduleInfo = await API.getSchedule({
      patientRut,
      patientRutDv,
      isPrevious: ripleyIntegrationButton.isPrevious,
      unitId,
      patientDocument,
    })
    const { pickups, additionalPatientData, error } = scheduleInfo
    if (pickups > 0) {
      setState({ pickups, additionalPatientData })
    } else {
      setState({ error })
    }
  } catch (e) {
    const error = typeof e === `string` ? e : `Ha ocurrido un error, reintente`
    return notification.error({ message: error })
  }
}

async function handlePickupButton({
  action,
  additionalPatientData,
  unitId,
  history,
  location,
}) {
  history.push({
    pathname: `/totem/${unitId}/print`,
    state: {
      action,
      additionalPatientData,
      ...location.state,
    },
  })
}

function getRipleyButtonGroup({
  additionalPatientData,
  unitId,
  history,
  location,
  isDisabled,
  props,
}) {
  return (
    <>
      <LineButton
        isLine={true}
        handleLineClick={() =>
          handlePickupButton({
            action: `TO_PAYMENT`,
            additionalPatientData,
            unitId,
            history,
            location,
          })
        }
        name="Público general"
        isDisabled={isDisabled}
        {...props}
      />
      <LineButton
        isLine={true}
        handleLineClick={() =>
          handlePickupButton({
            action: `TO_ATTENTION`,
            additionalPatientData,
            unitId,
            history,
            location,
          })
        }
        name="Preferencial"
        isDisabled={isDisabled}
        {...props}
      />
    </>
  )
}

function LinesAfterId({
  unit,
  goBackToCheckin,
  match,
  location,
  history,
  ...props
}) {
  const {
    id: unitId,
    visibleLines: linesToFilter,
    ignoreSchedule = false,
  } = unit

  const { isDisabled } = useDisabled()
  const [state, setState] = useStateWithMerge({
    error: null,
    pickups: 0,
    additionalPatientData: ``,
  })
  const { error, pickups, additionalPatientData } = state
  const {
    isRipleyIntegration,
    ripleyIntegrationButton,
    requestName,
    patientRut,
    patientRutDv,
    patientDocument,
  } = location?.state || {}

  if (isRipleyIntegration && props?.goBack) {
    delete props.goBack
  }
  const [currentLineCategory, setCurrentLineCategory] = useState(null)
  const goToLines = () =>
    history.push({
      pathname: `/totem/${unit.id}/lines`,
      state: { patientRut, patientRutDv, patientDocument },
    })
  const {
    data: { lines },
    refetch: refetchLines,
    isLoading,
  } = useQuery(
    [`totemLines`, linesToFilter, unitId, ignoreSchedule],
    () =>
      API.getTotemLines({
        linesToFilter,
        unitId,
        ignoreSchedule,
      }),
    {
      refetchInterval: 20000,
      refetchIntervalInBackground: true,
      retry: 3,
      retryDelay: 800,
      onError() {
        const key = `lines-${Date.now()}`
        notification.error({
          message: `No se ha podido establecer conexión con servidor`,
          description: `Presione o cierra este mensaje para reintentar`,
          duration: 15, //seconds
          onClick() {
            refetchLines()
            notification.close(key)
          },
          key,
        })
      },
      initialData: { lines: [] },
      onSuccess({ lines }) {
        if (
          lines[currentLineCategory] &&
          lines[currentLineCategory].lines == null
        ) {
          setCurrentLineCategory(null)
        }
      },
    },
  )
  const visibleLines = useMemo(() => {
    return lines[currentLineCategory] && lines[currentLineCategory].lines
      ? lines[currentLineCategory].lines
      : lines
  }, [lines, currentLineCategory])

  if (error) {
    return (
      <Container
        width="100%"
        height="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Container
          width={{ _: `40em` }}
          height={{ _: `37em` }}
          backgroundColor="white"
          justifyContent="space-evenly"
          flexWrap="no-wrap"
          alignItems="center"
          flexDirection="column"
          shadow="0px 3px 4px -4px rgba(0, 0, 0, 0.8)"
          paddingTop="5"
          paddingBottom="0"
          paddingX="3"
          position="relative"
        >
          <Error
            unitName={unit.name}
            goToLines={goToLines}
            {...error}
            country={unit.country}
          />
        </Container>
      </Container>
    )
  }

  return (
    <Lines
      isLoading={isLoading}
      isGroup={currentLineCategory != null}
      hasVisibleLines={visibleLines?.length > 0 || isRipleyIntegration}
      onGroupBack={() => setCurrentLineCategory(null)}
      unit={unit}
      isRipleyIntegration={isRipleyIntegration}
      pickups={pickups}
      {...props}
    >
      <Container
        justifyContent={isDisabled ? `flex-start` : `center`}
        alignItems="center"
        display="grid"
        gridGap="2vw"
        gridTemplateColumns={{
          md: `repeat(2, 1fr)`,
          _: isDisabled ? `repeat(2, 1fr)` : `1fr`,
        }}
        width="80%"
        padding={3}
      >
        {isRipleyIntegration && currentLineCategory == null && !pickups && (
          <LineButton
            isLine={true}
            handleLineClick={() =>
              handleLineClick({
                patientRut,
                patientRutDv,
                ripleyIntegrationButton,
                unitId,
                setState,
                patientDocument,
              })
            }
            name={ripleyIntegrationButton.title}
            isDisabled={isDisabled}
            {...props}
          />
        )}
        {isRipleyIntegration && pickups && pickups > 0
          ? getRipleyButtonGroup({
              unitId,
              history,
              additionalPatientData,
              isDisabled,
              location,
              props,
            })
          : visibleLines.map((line, index) => (
              <ListItem
                {...line}
                key={line.id}
                requestName={requestName}
                match={match}
                unitId={unit.id}
                setCurrentLineCategory={() => setCurrentLineCategory(index)}
                isDisabled={isDisabled}
              />
            ))}
      </Container>
    </Lines>
  )
}

export default LinesAfterId
