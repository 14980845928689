import axios from 'axios'
import notification from 'antd/es/notification'
import {
  ADMISSIONIST_TOKEN_KEY,
  API_BASE_URL,
  INACTIVITY_TIMEOUT_IN_SECONDS,
} from './constants'

const API = {}
const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  timeout: INACTIVITY_TIMEOUT_IN_SECONDS * 1000,
  validateStatus: (status) => {
    if (status === 401) {
      notification.error({
        message: `Su sesión ha caducado, será redirigida/o al login en breve.`,
        duration: 5,
      })
      localStorage.removeItem(ADMISSIONIST_TOKEN_KEY)
      setTimeout(() => window.location.reload(), 3000)
    } else {
      return true
    }
  },
})

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(ADMISSIONIST_TOKEN_KEY)
    if (token) {
      config.headers.Authorization = token
    }
    return config
  },
  (error) => Promise.reject(error),
)

axiosInstance.interceptors.response.use(
  (response) => {
    const {
      data: { result, error, success, alreadyCalled, isAutopago },
    } = response
    if (success) {
      return result
    } else {
      if (alreadyCalled) {
        return Promise.reject({ error, alreadyCalled })
      }
      if (isAutopago) {
        return Promise.reject({ error, ...isAutopago })
      }

      return Promise.reject(error)
    }
  },
  (error) => {
    if (error?.code === `ECONNABORTED`) {
      return Promise.reject(
        `No hay conexión con servidor o la red es muy lenta.`,
      )
    }
    return Promise.reject(error)
  },
)
//ADMISSIONIST

API.login = ({ email, password, modules, forceLogin = false, unitId }) =>
  axiosInstance.post(`/user/login`, {
    email,
    password,
    modules,
    forceLogin,
    unitId,
  })

API.logout = ({ userId, modules }) =>
  axiosInstance.post(`/user/logout`, { userId, modules })

API.getUserData = () => axiosInstance.get(`/user`)

API.getUserLines = ({ admissionistId, modules = [] }) =>
  axiosInstance.get(`/user/${admissionistId}/lines`, { params: { modules } })

API.getComposedData = () => axiosInstance.get(`/user/composedModeData`)
//MODULES AND ACTIVITIES
API.getModules = (unitId) =>
  axiosInstance.get(`/modules`, { params: { unitId } })

API.getActivityTypes = (unitId) =>
  axiosInstance.get(`/modules/activityTypes`, { params: { unitId } })

API.getCurrentActivityType = (admissionistId) =>
  axiosInstance.get(`/modules/${admissionistId}/activityType`)

API.updateActivityType = ({ userId, activityTypeId, modules }) =>
  axiosInstance.patch(`/modules/${userId}/updateActivityType`, {
    activityTypeId,
    modules,
  })

//LINES
API.getLines = (unitId) => axiosInstance.get(`/lines`, { params: { unitId } })

API.getMotives = ({ unitId, lineId }) =>
  axiosInstance.get(`/lines/motives`, { params: { unitId, lineId } })

//ATTENTION
API.getAttentions = (params) => axiosInstance.get(`attentions`, { params })

API.callAttention = ({ moduleId, attentionId, userId, isAhead = false }) =>
  axiosInstance.patch(`attentions/callAttention`, {
    moduleId,
    attentionId,
    userId,
    isAhead,
  })

API.recallAttention = ({ attentionId }) =>
  axiosInstance.patch(`attentions/recallAttention`, { attentionId })

API.endAttention = ({ attentionId, email = null, motiveId = null }) =>
  axiosInstance.patch(`attentions/endAttention`, {
    attentionId,
    email,
    motiveId,
  })

API.absentAttention = ({ attentionId }) =>
  axiosInstance.patch(`attentions/absentAttention`, { attentionId })

API.reopenAttention = ({ userId, moduleId, attentionId }) =>
  axiosInstance.post(`attentions/reopenAttention`, {
    userId,
    moduleId,
    attentionId,
  })

API.transferAttentionToLine = ({
  attentionId,
  lineId,
  email = null,
  motiveId = null,
  stockId,
}) =>
  axiosInstance.post(`attentions/transferAttention/line`, {
    attentionId,
    email,
    lineId,
    motiveId,
    stockId,
  })

API.transferAttentionToModule = ({
  attentionId,
  moduleId,
  email = null,
  motiveId = null,
}) =>
  axiosInstance.post(`attentions/transferAttention/module`, {
    attentionId,
    email,
    moduleId,
    motiveId,
  })

API.cancelAttention = ({ attentionId }) =>
  axiosInstance.patch(`attentions/cancelAttention`, { attentionId })

API.scheduleAttentions = (attentions) =>
  axiosInstance.post(`attentions/scheduleAttentions`, { attentions })

API.findAttentionByTicketIdentifier = ({ ticketIdentifier, lineIds }) =>
  axiosInstance.get(`attentions/${ticketIdentifier}/attention`, {
    params: { lineIds },
  })

API.planTicketJourney = ({ ticketSuggestedLines, ticketId }) =>
  axiosInstance.post(`attentions/planTicketJourney`, {
    ticketSuggestedLines,
    ticketId,
  })

API.editTicketPatient = (payload) =>
  axiosInstance.patch(`attentions/ticket/editPatient`, payload)

//TOTEM

API.getCurrentUnits = () => axiosInstance.get(`/totem/getCurrentUnits`)

API.totemLogin = ({ unitId, password }) =>
  axiosInstance.post(`/totem/loginUnit`, { unitId, password })

API.getTotemLines = ({
  linesToFilter = [],
  returnUnfiltered = false,
  ignoreSchedule = false,
  unitId,
  showAllUnits = false,
}) =>
  axiosInstance.get(`/totem/getLines`, {
    params: {
      linesToFilter,
      returnUnfiltered,
      ignoreSchedule,
      unitId,
      showAllUnits,
    },
  })

API.addTicket = (ticket) => axiosInstance.post(`/totem/addTicket`, ticket)

API.executeReservation = ({ patientRut, patientRutDv, unitId }) =>
  axiosInstance.post(`/totem/executeReservation`, {
    patientRut,
    patientRutDv,
    unitId,
  })

API.updateTicket = ({ id, wasPrinted }) =>
  axiosInstance.put(`/totem/updateTicket`, { id, wasPrinted })

API.totemGetUnits = () => axiosInstance.get(`/totem/getUnits`)
API.getSchedule = (params) =>
  axiosInstance.get(`/totem/getSchedule`, { params })

API.submitBonus = (params) =>
  axiosInstance.post(`/totem/submitBonus`, params, { timeout: 60000 })

// TV

API.lastCalled = (unitId) =>
  axiosInstance.get(`/tv/lastCalled`, { params: { unitId } })
API.nextCalls = (payload) => axiosInstance.post(`/tv/nextCalls`, payload)
API.getSoundPath = (soundKey) =>
  axiosInstance.get(`/tv/soundPath`, { params: { soundKey } })
// STOCK
API.getStock = (payload) => axiosInstance.get(`/stock/`, { params: payload })
API.createStock = (payload) => axiosInstance.post(`/stock/create`, payload)
API.editStock = ({ id, ...payload }) =>
  axiosInstance.put(`/stock/edit/${id}`, payload)
API.getStocks = (payload) =>
  axiosInstance.get(`/stock/all`, { params: payload })
API.getStockTypes = () => axiosInstance.get(`/stockTypes/getStockTypes`)
//ANALOG

API.generateAnalogTickets = (payload) =>
  axiosInstance.post(`/analog/generateTickets`, payload)

API.callAnalogAttention = (payload) =>
  axiosInstance.post(`/analog/call`, payload)

API.storeCategoryStats = (payload) => axiosInstance.post(`/stats`, payload)

//APPOINTMENTS

API.getAppointments = (payload) =>
  axiosInstance.get(`/appointments/getAppointments`, { params: payload })

// ITEMS
API.getItemStatuses = () => axiosInstance.get(`/items/statuses`)
API.getItems = (params) => axiosInstance.get(`/items`, { params })
API.updateItemStatus = (body) => axiosInstance.patch(`/items`, body)

// PAYMENTS
API.createImedAppointment = (payload) =>
  axiosInstance.post(`/imed/create`, payload)
API.validateImedAdmission = (payload) =>
  axiosInstance.post(`imed/validate`, payload)
API.notifyImedPayment = (payload) => axiosInstance.post(`imed/notify`, payload)
API.emitImedBonus = (payload) => axiosInstance.post(`imed/bonus`, payload)
API.getImedBonus = ({ id, corporationId }) =>
  axiosInstance.get(`imed/bonus/${id}`, { params: { corporationId } })

//TICKETS
API.getTickets = (unitId) =>
  axiosInstance.get(`/tickets`, { params: { unitId } })
//End attention from integration
API.endAttentionFromIntegration = ({ attentionId }) =>
  axiosInstance.patch(`integration/endAttention`, { attentionId })

//Generic hook to after financer AUTOPAGO
API.afterFinancerHook = (params) =>
  axiosInstance.get(`totem/afterFinancerHook`, { params })

//Generic hook to after copayment AUTOPAGO
API.afterCopaymentHook = (params) =>
  axiosInstance.get(`totem/afterCopaymentHook`, { params })

//Generic hook to after copayment AUTOPAGO
API.beforeFinaleHook = (params) =>
  axiosInstance.post(`totem/beforeFinaleHook`, params)

//Send documents by emails
API.sendDocuments = (payload) =>
  axiosInstance.post(`totem/sendDocuments`, payload)

//getDocument function for ambulatory unit integration
API.beforeDocumentObtainSelectionHook = (payload) =>
  axiosInstance.post(`totem/beforeDocumentObtainSelection`, payload)

//Generic hook to send appointment data before set hour AUTOPAGO
API.sendAppointmentAgendaX = (payload) =>
  axiosInstance.post(`totem/sendAppointmentAgendaX`, payload)

//Multitransfer
//getTicketSuggestedLines function for multitransfer modal data popullation
API.getTicketSuggestedLines = (params) =>
  axiosInstance.get(`multiTransfer/getTicketSuggestedLines`, { params })

//addLineToPatientJourney function to add line to patient journey
API.addLineToPatientJourney = (payload) =>
  axiosInstance.post(`multiTransfer/addLineToPatientJourney`, payload)
//deleteLineFromPatientJourney function to delete line from patient journey

API.deleteLineFromPatientJourney = (payload) =>
  axiosInstance.delete(`multiTransfer/deleteLineFromPatientJourney`, {
    data: payload,
  })

//markAttentionAsDone function to mark one attention from a battery as done
//TODO: este endpoint se implementara en una proxima version
API.markAttentionAsDone = (payload) =>
  axiosInstance.patch(`multiTransfer/markAttentionAsDone`, payload)

API.saveFingerprintError = (payload) =>
  axiosInstance.post(`hardwareServer/saveFingerprintError`, payload)

API.savePOSError = (payload) =>
  axiosInstance.post(`hardwareServer/savePOSError`, payload)

API.saveAutopagoRecord = (payload) =>
  axiosInstance.post(`totem/saveAutopagoRecord`, payload)
API.endPatientJorney = (payload) =>
  axiosInstance.post(`multitransfer/endPatientJorney`, payload)

export default API
